import { MESSAGE_TYPE, COMPONENTS } from "../utils/Constants";
import uuidv4 from "uuid";
import { makeVar } from "@apollo/client";

export const messageSetVar = makeVar([]);

export function deleteMessagesByLocation(location) {
  messageSetVar([...messageSetVar().filter((m) => m.location !== location)]);
}

export function getMessageTextFromApolloError(apolloError) {
  const { graphQLErrors, networkError } = apolloError;

  const message = {
    header: "Une erreur est survenue",
  };

  if ((!graphQLErrors || graphQLErrors.length === 0) && !networkError) {
    message.content = apolloError.toString();
  }

  if (graphQLErrors && graphQLErrors.length !== 0) {
    message.header = "Erreur lors du traitement de la requête";
    message.messageList = graphQLErrors.map(({ message }) => message);
  }

  if (networkError) {
    message.header = "Erreur réseau";
    message.content = networkError.toString();
  }

  return message;
}

export function addMessage(message) {
  message.id = uuidv4();
  message.__typename = "MessageType";

  if (!message.type) {
    message.type = MESSAGE_TYPE.WARNING;
  }

  if (message.autoClose === undefined || message.autoClose === null) {
    message.autoClose = true;
  }

  if (message.closeTime === undefined) {
    if (message.autoClose) {
      message.closeTime = 10000;
    } else {
      message.closeTime = null;
    }
  }

  if (!message.location) {
    message.location = COMPONENTS.COMMON.INDEX;
  }

  if (message.header === undefined) {
    message.header = null;
  }

  if (message.content === undefined) {
    message.content = null;
  }

  if (message.messageList === undefined) {
    message.messageList = null;
  }

  if (message.rawHtml === undefined) {
    message.rawHtml = null;
  }

  messageSetVar([...messageSetVar(), message]);
}

export function addErrorMessage(message) {
  message.type = MESSAGE_TYPE.NEGATIVE;
  addMessage(message);
}

export function addSuccessMessage(message) {
  message.type = MESSAGE_TYPE.POSITIVE;
  addMessage(message);
}

export function addErrorToastMessage(message) {
  message.location = COMPONENTS.COMMON.INDEX;
  addErrorMessage(message);
}

export function addSuccessToastMessage(message) {
  message.location = COMPONENTS.COMMON.INDEX;
  addSuccessMessage(message);
}

export function handleApolloError(error) {
  addErrorToastMessage(getMessageTextFromApolloError(error));
}
