// React
import React from "react";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteLayoutModule: null,
    };
  }

  componentDidMount() {
    const layoutFolder = this.props.website.homeFolder || "Default";
    import(`./${layoutFolder}/pages/SiteLayout`).then((module) => {
      this.setState({ siteLayoutModule: module.default });
    });
  }

  render() {
    const { siteLayoutModule: SiteLayoutComponent } = this.state;

    return (
      <>
        {SiteLayoutComponent && (
          <SiteLayoutComponent website={this.props.website} />
        )}
      </>
    );
  }
}

export default Layout;
