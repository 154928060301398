// GraphQL
import { gql } from "@apollo/client";
import {
  PROJECT_FRAG,
  ROOT_FOLDER_FRAG,
  FEATURE_FRAG,
  FEATURE_LIGHT_FRAG,
  USER_FRAG,
} from "./fragments";

export const GET_PROFILE = gql`
  query GetCurrentUser {
    currUser {
      ...UserFrag
    }
  }
  ${USER_FRAG}
`;

export const GET_PROJECT = gql`
  query project($projectId: ID!) {
    project(projectId: $projectId) {
      ...ProjectFrag
    }
  }
  ${PROJECT_FRAG}
`;

export const PROJECTS_ASSIGNED_TO_CURRENT_USER_FOR_CURRENT_SITE = gql`
  query currUserCurrSiteAssignedProjectSet {
    currUserCurrSiteAssignedProjectSet {
      id
      name
      rootFolder {
        ...RootFolderFrag
      }
      illustrationPath
      description
    }
  }
  ${ROOT_FOLDER_FRAG}
`;

export const GET_LAYER_WITH_FEATURES = gql`
  query LayerWithFeatures($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      pointFeatureSet {
        ...FeatureFrag
      }
      lineFeatureSet {
        ...FeatureFrag
      }
      polyFeatureSet {
        ...FeatureFrag
      }
    }
  }
  ${FEATURE_FRAG}
`;

export const GET_LAYER_WITH_POINT_FEATURES_WITH_FILLED_FOR_DECORATOR = gql`
  query LayerWithPointFeaturesWithFilledForDecorator(
    $layerId: ID!
    $questionnaireFieldId: ID!
  ) {
    layer(layerId: $layerId) {
      id
      pointFeatureSet {
        ...FeatureLightFrag
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
        currentUserIsOwner
      }
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_LINE_FEATURES_WITH_FILLED_FOR_DECORATOR = gql`
  query LayerWithLineFeaturestWithFilledForDecorator(
    $layerId: ID!
    $questionnaireFieldId: ID!
  ) {
    layer(layerId: $layerId) {
      id
      lineFeatureSet {
        ...FeatureLightFrag
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
        currentUserIsOwner
      }
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_POLY_FEATURES_WITH_FILLED_FOR_DECORATOR = gql`
  query LayerWithPolyFeaturesWithFilledForDecorator(
    $layerId: ID!
    $questionnaireFieldId: ID!
  ) {
    layer(layerId: $layerId) {
      id
      polyFeatureSet {
        ...FeatureLightFrag
        selectedFieldForDecorator(questionnaireFieldId: $questionnaireFieldId)
        currentUserIsOwner
      }
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_FEATURES_LIGHT = gql`
  query LayerWithFeaturesLight($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      pointFeatureSet {
        ...FeatureLightFrag
      }
      lineFeatureSet {
        ...FeatureLightFrag
      }
      polyFeatureSet {
        ...FeatureLightFrag
      }
      polyColor
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_POINT_FEATURES_LIGHT = gql`
  query LayerWithPointFeaturesLight($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      pointFeatureSet {
        ...FeatureLightFrag
      }
      markerColor
      marker
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_LINE_FEATURES_LIGHT = gql`
  query LayerWithLineFeaturesLight($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      lineFeatureSet {
        ...FeatureLightFrag
      }
      lineColor
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const GET_LAYER_WITH_POLY_FEATURES_LIGHT = gql`
  query LayerWithPolyFeaturesLight($layerId: ID!) {
    layer(layerId: $layerId) {
      id
      polyFeatureSet {
        ...FeatureLightFrag
      }
      polyColor
    }
  }
  ${FEATURE_LIGHT_FRAG}
`;

export const CURR_USER_CURR_SITE_HAS_ACCESS = gql`
  query currUserCurrSiteHasAccess {
    currUserCurrSiteHasAccess
  }
`;

export const PUBLIC_CURR_WEBSITE = gql`
  query GetPublicCurrentWebsite {
    publicCurrWebsite {
      id
      homeFolder
      mainModule {
        id
        name
        menu
        private
      }
      site {
        id
        domain
        name
        moduleSet {
          id
          name
          menu
          private
        }
        projectSet {
          id
          name
        }
      }
    }
  }
`;

export const PUBLIC_CURR_WEBSITE_PROJECT_SET_TASK_SET_FOR_PREFETCH = gql`
  query GetPublicCurrentWebsiteForPrefetch {
    publicCurrWebsite {
      id
      site {
        id
        projectSet {
          id
          taskSet {
            id
            layerSet {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_FEATURES_RANGE = gql`
  query featureSet($layerId: ID!, $elemPerPage: Int!, $page: Int!) {
    featureSet(layerId: $layerId, elemPerPage: $elemPerPage, page: $page) {
      ...FeatureFrag
    }
  }
  ${FEATURE_FRAG}
`;

export const GET_PUBLIC_PROJECT = gql`
  query publicProject($projectId: ID!) {
    publicProject(projectId: $projectId) {
      id
      name
      planning
      planningPath
      rootFolder {
        ...RootFolderFrag
      }
      site {
        id
        name
      }
    }
  }
  ${ROOT_FOLDER_FRAG}
`;
