/******************************* */
/*           COMMON              */
/******************************* */
export const AUTH_TOKEN = "auth-token";

// Messages
export const MESSAGE_TYPE = {
  POSITIVE: "MESSAGE_TYPE_POSITIVE",
  WARNING: "MESSAGE_TYPE_WARNING",
  NEGATIVE: "MESSAGE_TYPE_NEGATIVE",
};

// Components
export const COMPONENTS = {
  COMMON: {
    INDEX: "COMPONENT_COMMON_INDEX",
    PROFILE: "COMPONENT_COMMON_PROFILE",
  },
  COLLABORATIVE_MAP: {
    CONTROLLER: "COMPONENT_COLLABORATIVE_MAP_CONTROLLER",
  },
  FILEMANAGER: {
    MANAGE_PERMISSIONS: "COMPONENT_FILEMANAGER_MANAGE_PERMISSIONS",
  },
  FORUM: {
    ADD_POST: "COMPONENT_FORUM_ADD_POST",
  },
  SITE: {
    FIGEAC: {
      LOGIN: "COMPONENT_SITE_FIGEAC_LOGIN",
    },
  },
};

// Token MapBox
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoieXZhaW5tIiwiYSI6ImNrMnZyZzdyODA3aWIzaHFsNDJ3eDlpMzMifQ.bSF9_23BfhATwmQFfGryTw";

// Clé ign
export const IGN_KEY = "rcvlahlyq4mm22jvo1rklhpb";
// API carto
export const URL_APICARTO = "https://apicarto.ign.fr/api/cadastre/";

/******************************* */
/*      COLLABORATIVE MAP        */
/******************************* */
export const COLLABORATIVE_MAP_GEOMETRY_TYPES = {
  POINT: "COLLABORATIVE_MAP_GEOMETRY_TYPE_POINT",
  LINE: "COLLABORATIVE_MAP_GEOMETRY_TYPE_LINE",
  POLYGON: "COLLABORATIVE_MAP_GEOMETRY_TYPE_POLYGON",
};

export const COLLABORATIVE_MAP_TOOLS = {
  NONE: "COLLABORATIVE_MAP_TOOL_NONE",
  ADD_POINT: "COLLABORATIVE_MAP_TOOL_ADD_POINT",
  ADD_LINE: "COLLABORATIVE_MAP_TOOL_ADD_LINE",
  ADD_POLYGON: "COLLABORATIVE_MAP_TOOL_ADD_POLYGON",
  EDIT_GEOMETRY: "COLLABORATIVE_MAP_TOOL_EDIT_GEOMETRY",
  EDIT_QUESTIONNAIRE: "COLLABORATIVE_MAP_TOOL_EDIT_QUESTIONNAIRE",
  DELETE_FEATURE: "COLLABORATIVE_MAP_TOOL_DELETE_FEATURE",
  EXPORT_SHP: "COLLABORATIVE_MAP_TOOL_EXPORT_SHP",
};

export const COLLABORATIVE_MAP_TOOL_TO_GEOMETRY_TYPE_CODE = {
  [COLLABORATIVE_MAP_TOOLS.ADD_POINT]: COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT,
  [COLLABORATIVE_MAP_TOOLS.ADD_LINE]: COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE,
  [COLLABORATIVE_MAP_TOOLS.ADD_POLYGON]:
    COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON,
};

// Collaborative Map Permissions
export const COLLABORATIVE_MAP_PERMISSIONS = {
  ADD_POINT_TO_LAYER: "add_point_to_layer_",
  ADD_LINE_TO_LAYER: "add_line_to_layer_",
  ADD_POLYGON_TO_LAYER: "add_polygon_to_layer_",
  EDIT_GEOMETRY_ON_LAYER: "edit_geometry_on_layer_",
  EDIT_QUESTIONNAIRE_ON_LAYER: "edit_questionnaire_on_layer_",
  DELETE_GEOMETRY_ON_LAYER: "delete_geometry_on_layer_",
  EXPORT_SHP_FROM_LAYER: "export_shp_from_layer_",
  VIEW_OTHERS_FEATURES: "view_others_features_on_layer_",
  VIEW_OTHERS_INFOS: "view_others_infos_on_layer_",
  EDIT_OTHERS_INFOS: "edit_others_infos_on_layer_",
  EDIT_OTHERS_GEOMETRIES: "edit_others_geometries_on_layer_",
  DELETE_OTHERS_GEOMETRIES: "delete_others_geometries_on_layer_",
};

// Caneva de marqueur html
export const DEFAULT_COLOR = "#3388ff";

export const DEFAULT_MARKER_COLOR = "#3388ff";

export const DEFAULT_MARKER_SYMBOL = "circle-15.svg";

export const MARKER_HTML_TEMPLATE = `
    <div style='background-color: MARKER_BG_COLOR;
                border-radius: 18px 18px 18px 18px;
                box-shadow: 6px 13px 8px -4px black;
                width: 36px;
                height: 36px;
                line-height: 36px;
                opacity: 0.9;
                text-align: center;
                padding: 10px 0 0 10px;'>
                <div style="background-color: white;
                            width: 15px;
                            height: 15px;
                            -webkit-mask:  url(/static/back/resources/media/markers/MARKER_ICON);
                            mask-image: url(/static/back/resources/media/markers/MARKER_ICON);"></div>
    </div>
    <div style="border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 21px solid MARKER_BG_COLOR;
                height: 0;
                left: 6px;
                position: relative;
                top: -4px;
                width: 0;"></div>
    `;

/******************************* */
/*        QUESTIONNAIRE          */
/******************************* */
export const QUESTIONNAIRE_FIELD_TYPES = {
  TEXT: "QUESTIONNAIRE_FIELD_TYPE_TEXT",
  RADIO: "QUESTIONNAIRE_FIELD_TYPE_RADIO",
  SELECT: "QUESTIONNAIRE_FIELD_TYPE_SELECT",
  CHECKBOX: "QUESTIONNAIRE_FIELD_TYPE_CHECKBOX",
  DECIMAL: "QUESTIONNAIRE_FIELD_TYPE_DECIMAL",
  DATE: "QUESTIONNAIRE_FIELD_TYPE_DATE",
  IMAGE: "QUESTIONNAIRE_FIELD_TYPE_IMAGE",
  DOCUMENT: "QUESTIONNAIRE_FIELD_TYPE_DOCUMENT",
};

export const QUESTIONNAIRE_FILTER = {
  ORPHELIN: "QUESTIONNAIRE_FILTER_ORPHELIN",
  NON_ORPHELIN: "QUESTIONNAIRE_FILTER_NON_ORPHELIN",
  ALL: "QUESTIONNAIRE_FILTER_ALL",
};

export const QUESTIONNAIRE_MODES = {
  CREATE: "QUESTIONNAIRE_MODE_CREATE",
  EDIT: "QUESTIONNAIRE_MODE_EDIT",
};

/******************************* */
/*        FILEMANAGER            */
/******************************* */
export const FILEMANAGER_PERMISSIONS = {
  view_folder_: "canViewFolder",
  change_folder_: "canChangeFolder",
  move_folder_: "canMoveFolder",
  delete_folder_: "canDeleteFolder",
  add_folder_to_folder_: "canAddFolder",
  add_document_to_folder_: "canAddDocument",
  view_document_: "canViewDocument",
  move_document_: "canMoveDocument",
  delete_document_: "canDeleteDocument",
};

export const FILEMANAGER_PERMISSIONS_REVERSE = {
  canViewFolder: "view_folder_",
  canChangeFolder: "change_folder_",
  canMoveFolder: "move_folder_",
  canDeleteFolder: "delete_folder_",
  canAddFolder: "add_folder_to_folder_",
  canAddDocument: "add_document_to_folder_",
  canViewDocument: "view_document_",
  canMoveDocument: "move_document_",
  canDeleteDocument: "delete_document_",
};

/******************************* */
/*        DRAWING                */
/******************************* */
export const DRAWING_TOOLS = {
  NONE: "DRAWING_TOOL_NONE",
  PENCIL: "DRAWING_TOOL_PENCIL",
  TEXT: "DRAWING_TOOL_TEXT",
  ERASER: "DRAWING_TOOL_ERASER",
  MOVE: "DRAWING_TOOL_MOVE",
};

/******************************* */
/*            USERS              */
/******************************* */
export const USER_TYPES = {
  NATURAL_PERSON: "USER_TYPE_NATURAL_PERSON",
  LEGAL_PERSON: "USER_TYPE_LEGAL_PERSON",
};
