// GraphQL
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { messageSetVar } from "./message";
import { makeVar } from "@apollo/client";

// Constants
import { AUTH_TOKEN } from "../utils/Constants";

export const menuDisabledVar = makeVar(false);
export const fullScreenVar = makeVar(false);
export const isConnectedVar = makeVar(false);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        messageSet: {
          read() {
            return messageSetVar();
          },
        },
        menuDisabled: {
          read() {
            return menuDisabledVar();
          },
        },
        isConnected: {
          read() {
            return isConnectedVar();
          },
        },
        currUserFolderSet: {
          merge(existing = [], incoming) {
            return [...incoming];
          },
        },
        currUserDocumentSet: {
          merge(existing = [], incoming) {
            return [...incoming];
          },
        },
      },
    },
    LayerType: {
      fields: {
        isCheckedForDisplay: {
          read(isCheckedForDisplay, { readField }) {
            if (
              isCheckedForDisplay === undefined ||
              isCheckedForDisplay === null
            ) {
              return readField("visibleByDefault");
            }
            return isCheckedForDisplay;
          },
        },
        pointFeatureSet: {
          merge(existing = [], incoming) {
            return [...incoming];
          },
        },
      },
    },
    CalendarType: {
      fields: {
        selected: {
          read(selected = false) {
            return selected;
          },
        },
      },
    },
    DocumentType: {
      fields: {
        selectedForDownload: {
          read(selectedForDownload = false) {
            return selectedForDownload;
          },
        },
      },
    },
    FolderType: {
      fields: {
        selectedForDownload: {
          read(selectedForDownload = false) {
            return selectedForDownload;
          },
        },
      },
    },
  },
});

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  const authorizationHeader = token ? `JWT  ${token}` : null;

  operation.setContext({
    headers: {
      authorization: authorizationHeader,
    },
  });
  return forward(operation);
});

const uploadLink = createUploadLink({ uri: "/graphql/" });

const typeDefs = `
    type MessageType {
        id: ID!
        autoClose: Boolean!
        closeTime: Int
        location: String!
        type: String!
        header: String
        content: String
        messageList: [String]
    }
`;

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, uploadLink]),
  typeDefs,
});

client.onResetStore(() => {
  menuDisabledVar(false);
  fullScreenVar(false);
  isConnectedVar(false);
  messageSetVar([]);
});

export default client;
