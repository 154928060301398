// React
import React from "react";

// Semantic ui
import { Loader } from "semantic-ui-react";

// GraphQL
import { useQuery } from "@apollo/client";
import { PUBLIC_CURR_WEBSITE } from "./Common/_GraphQL/queries";

// Components
import Layout from "./Sites/Layout";
import ErrorMessage from "./Common/components/ErrorMessage";

function App() {
  const { loading, error, data } = useQuery(PUBLIC_CURR_WEBSITE);

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return <Layout website={data.publicCurrWebsite} />;
}

export default App;
