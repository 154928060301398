// GraphQL
import { gql } from "@apollo/client";

export const LABEL_FRAG = gql`
  fragment LabelFrag on LabelType {
    id
    text
    code
  }
`;

export const USER_FRAG = gql`
  fragment UserFrag on UserType {
    id
    email
    firstName
    lastName
    isActive
    profile {
      avatar
      avatarPath
      name
      nature {
        id
        code
      }
    }
  }
`;

export const DRAWING_FRAG = gql`
  fragment DrawingFrag on DrawingType {
    id
    name
    background
    backgroundPath
    paperjsProject
    currentUserCanEdit
  }
`;

export const ROOT_FOLDER_FRAG = gql`
  fragment RootFolderFrag on FolderType {
    id
    name
  }
`;

export const PROJECT_FRAG = gql`
  fragment ProjectFrag on ProjectType {
    id
    name
    description
    rootFolder {
      ...RootFolderFrag
    }
    site {
      id
      name
      moduleSet {
        id
        name
      }
    }
    planning
    planningPath
    illustration
    illustrationPath
    active
  }
  ${ROOT_FOLDER_FRAG}
`;

export const QUESTIONNAIRE_FIELD_LABEL_FRAG = gql`
  fragment QuestionnaireFieldLabelFrag on QuestionnaireFieldLabelType {
    id
    text
    marker
    markerColor
    lineColor
    polyColor
  }
`;

export const QUESTIONNAIRE_FIELD_FRAG = gql`
  fragment QuestionnaireFieldFrag on QuestionnaireFieldType {
    id
    text
    shpField
    placeHolder
    required
    readOnly
    order
    helptext
    minimum
    maximum
    multiline
    decimal
    decimalDigits
    parentQuestionnaireField {
      id
      text
      fieldType {
        id
        text
        code
      }
      questionnairefieldlabelSet {
        ...QuestionnaireFieldLabelFrag
      }
    }
    parentQuestionnaireFieldAnswer
    parentQuestionnaireFieldLabel {
      ...QuestionnaireFieldLabelFrag
    }
    questionnairefieldlabelSet {
      ...QuestionnaireFieldLabelFrag
    }
    fieldType {
      id
      text
      code
    }
  }
  ${QUESTIONNAIRE_FIELD_LABEL_FRAG}
`;

export const FEATURE_FRAG = gql`
  fragment FeatureFrag on FeatureType {
    id
    geometryType {
      ...LabelFrag
    }
    data
    filledQuestionnaireSet {
      id
      user {
        id
      }
      creationDate
      filledquestionnairefieldSet {
        id
        questionnaireField {
          ...QuestionnaireFieldFrag
        }
        questionnaireFieldLabel {
          id
          text
        }
        text
        checked
        value
        date
        questionnairemediaSet {
          id
          name
        }
      }
    }
    user {
      firstName
      profile {
        avatar
        avatarPath
        nature {
          id
          code
        }
        name
      }
    }
    currentUserIsOwner
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
  ${LABEL_FRAG}
`;

export const FEATURE_LIGHT_FRAG = gql`
  fragment FeatureLightFrag on FeatureType {
    id
    geometryType {
      ...LabelFrag
    }
    data
    currentUserIsOwner
  }
  ${LABEL_FRAG}
`;

export const NEWS_FRAG = gql`
  fragment NewsFrag on NewsType {
    id
    title
    slug
    excerpt
    content
    date
    illustration
    illustrationPath
  }
`;

export const DEFAULT_FOLDER_GROUP_PERMISSION_FRAG = gql`
  fragment DefaultFolderGroupPermissionFrag on DefaultFolderGroupPermissionType {
    project {
      id
      name
    }
    group {
      id
      name
    }
    permission
  }
`;

export const GROUP_PERMISSION_FRAG = gql`
  fragment GroupPermissionFrag on GroupPermissionObjectType {
    groupId
    permission
  }
`;

export const DEFAULT_DOCUMENT_GROUP_PERMISSION_FRAG = gql`
  fragment DefaultDocumentGroupPermissionFrag on DefaultDocumentGroupPermissionType {
    project {
      id
      name
    }
    group {
      id
      name
    }
    permission
  }
`;
