// React
import React, { useState } from "react";
import ReactDOM from "react-dom";

// React Router
import { BrowserRouter } from "react-router-dom";

// Semantic ui
import "./semantic/dist/semantic.min.css";

// Styles
import "./styles/index.css";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

// GraphQL
import { ApolloProvider } from "@apollo/client";

// Application
import App from "./App";
import client, { isConnectedVar } from "./_GraphQL/graphQLClient";
import MessageDisplayerToast from "./Common/components/MessageDisplayerToast";

// Constants
import { AUTH_TOKEN } from "./utils/Constants";
import { Message } from "semantic-ui-react";

const subdomain = window.location.host.split(".")[0];

const EnvWarning = () => {
  const [visible, setVisibility] = useState(true);
  let env = null;
  let color = null;

  if (subdomain.includes("localhost")) {
    color = "blue";
    env = "Local";
  } else if (subdomain.includes("-dev")) {
    color = "orange";
    env = "de développement";
  } else if (subdomain.includes("-test")) {
    color = "yellow";
    env = "de test";
  }

  if (!env || !visible) return null;

  return (
    <Message color={color} onDismiss={() => setVisibility(false)}>
      Environnement {env}
    </Message>
  );
};

const Root = () => (
  <>
    <EnvWarning />
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <MessageDisplayerToast />
    </ApolloProvider>
  </>
);

if (localStorage.getItem(AUTH_TOKEN) !== null) {
  isConnectedVar(true);
}

ReactDOM.render(<Root />, document.getElementById("root"));
